import {
  CButton,
  CCallout,
  CCol,
  CContainer,
  CForm,
  CFormGroup,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
  CSpinner,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import DepositsService from "../../../services/DepositsService";
import MemberSelect from "./MemberSelect";

const NewModal = (props) => {
  const [modalNewLoading, setModalNewLoading] = useState(false);
  const [newDeposit, setNewDeposit] = useState({
    member: "",
    amount: 0,
    date: undefined,
    remark: "",
    userId: undefined,
  });

  const [members, setMembers] = useState([]);

  // Lifecycle
  useEffect(() => {
    const newMembers = [];
    props.sumDeposits.forEach((s) =>
      newMembers.push({ value: s._id, label: s._id })
    );
    setMembers(newMembers);
  }, [props.sumDeposits]);

  const [error, setError] = useState({
    exists: false,
    text: "",
  });

  function handleChange(e) {
    e.preventDefault();
    var value = e.target.value;
    var name = e.target.name;
    let share = { ...newDeposit };
    share[name] = value;
    setNewDeposit(share);
  }

  // Save
  function saveNewDeposit() {
    setModalNewLoading(true);
    const deposit = {
      _id: null,
      member: newDeposit.member,
      amount: parseFloat(newDeposit.amount),
      date: newDeposit.date,
      remark: newDeposit.remark,
      userId: null,
    };

    DepositsService.createDeposit(deposit)
      .then((data) => {
        props.loadDeposits();
        setModalNewLoading(false);
        props.resetModalNewState();
        setNewDeposit({
          member: "",
          amount: 0,
          date: undefined,
          remark: "",
          userId: undefined,
        });
        props.toggleNew();
      })
      .catch((e) => {
        setError({ exists: true, text: e });
      });
  }

  return (
    <>
      <CModal show={props.modalNew} onClose={props.toggleNew}>
        <CModalHeader closeButton>Neue Einzahlung hinzufügen</CModalHeader>
        <CModalBody>
          <center hidden={!modalNewLoading} width="100%">
            <CSpinner style={{ marginLeft: "auto", marginRight: "auto" }} />
          </center>
          <div hidden={modalNewLoading}>
            <CContainer fluid>
              <CRow>
                <CCol sm="12">
                  <CCallout
                    color="danger"
                    className={"bg-white"}
                    visible={error.exists.toString()}
                  >
                    <strong className="h5">{error.text}</strong>
                  </CCallout>
                  <CForm>
                    <CRow>
                      {/* <CCol sm="12">
                        <CFormGroup>
                          <CLabel htmlFor="nf-member">Einzahlender</CLabel>
                          <CInput
                            type="text"
                            id="nf-member"
                            name="member"
                            placeholder="Einzahlung von"
                            value={newDeposit.member}
                            onChange={handleChange}
                          />
                        </CFormGroup>
                      </CCol> */}
                      <CCol sm="12">
                        <CFormGroup>
                          <CLabel htmlFor="nf-member">Einzahlender</CLabel>
                          {/* <CInput
                            type="text"
                            id="nf-member"
                            name="member"
                            placeholder="Einzahlung von"
                            value={newDeposit.member}
                            onChange={handleChange}
                          /> */}
                          <MemberSelect
                            options={members}
                            setMembers={setMembers}
                            handleChange={handleChange}
                            setNewDeposit={setNewDeposit}
                            newDeposit={newDeposit}
                          />
                        </CFormGroup>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol sm="6">
                        <CFormGroup>
                          <CLabel htmlFor="nf-date">Einzahlungsdatum</CLabel>
                          <CInput
                            type="date"
                            id="nf-date"
                            name="date"
                            placeholder="Einzahlungsdatum"
                            value={newDeposit.date}
                            onChange={handleChange}
                          />
                        </CFormGroup>
                      </CCol>
                      <CCol sm="6">
                        <CFormGroup>
                          <CLabel htmlFor="nf-amount">Betrag</CLabel>
                          <CInput
                            type="number"
                            id="nf-amount"
                            name="amount"
                            placeholder="Anzahl"
                            value={newDeposit.amount}
                            onChange={handleChange}
                          />
                        </CFormGroup>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol sm="12">
                        <CFormGroup>
                          <CLabel htmlFor="es-remark">Bemerkung</CLabel>
                          <CInput
                            type="text"
                            id="es-remark"
                            name="remark"
                            placeholder="Bemerkung"
                            value={newDeposit.remark}
                            onChange={handleChange}
                          />
                        </CFormGroup>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCol>
              </CRow>
            </CContainer>
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton color="primary" onClick={saveNewDeposit}>
            Speichern
          </CButton>
          <CButton color="secondary" onClick={props.toggleNew}>
            Abbrechen
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default NewModal;
