"use strict";

import HttpService from "./HttpService";

export default class DepositsService {
  static baseURL() {
    return process.env.REACT_APP_BACKEND_URL + "/deposits";
  }

  // Get all deposits
  static getAllDeposits() {
    return new Promise((resolve, reject) => {
      HttpService.get(
        `${DepositsService.baseURL()}`,
        function (data) {
          if (data != undefined || Object.keys(data).length !== 0) {
            resolve(data);
          } else {
            reject("Error while retrieving share");
          }
        },
        function (textStatus) {
          reject(textStatus);
        }
      );
    });
  }

  // Get deposit by id
  static getDeposit(id) {
    return new Promise((resolve, reject) => {
      HttpService.get(
        `${DepositsService.baseURL()}/${id}`,
        function (data) {
          if (data != undefined || Object.keys(data).length !== 0) {
            resolve(data);
          } else {
            reject("Error while retrieving deposit");
          }
        },
        function (textStatus) {
          reject(textStatus);
        }
      );
    });
  }

  // Get deposit sum per member
  static getSumPerMember() {
    return new Promise((resolve, reject) => {
      HttpService.get(
        `${DepositsService.baseURL()}/sum/members`,
        function (data) {
          if (data != undefined || Object.keys(data).length !== 0) {
            resolve(data);
          } else {
            reject("Error while retrieving deposit");
          }
        },
        function (textStatus) {
          reject(textStatus);
        }
      );
    });
  }

  // Delete deposit by id
  static deleteDeposit(id) {
    return new Promise((resolve, reject) => {
      HttpService.remove(
        `${DepositsService.baseURL()}/${id}`,
        function (data) {
          if (data.message != undefined) {
            resolve(data.message);
          } else {
            reject("Error while deleting");
          }
        },
        function (textStatus) {
          reject(textStatus);
        }
      );
    });
  }

  // update deposit
  static updateDeposit(deposit) {
    return new Promise((resolve, reject) => {
      HttpService.put(
        `${DepositsService.baseURL()}/${deposit._id}`,
        deposit,
        function (data) {
          resolve(data);
        },
        function (textStatus) {
          reject(textStatus);
        }
      );
    });
  }

  // create a new deposit
  static createDeposit(deposit) {
    return new Promise((resolve, reject) => {
      HttpService.post(
        DepositsService.baseURL(),
        deposit,
        function (data) {
          resolve(data);
        },
        function (textStatus) {
          reject(textStatus);
        }
      );
    });
  }
}
