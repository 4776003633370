import {
  CButton,
  CCallout,
  CCol,
  CContainer,
  CForm,
  CFormGroup,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
  CSpinner,
} from "@coreui/react";
import React, { useState } from "react";
import CashService from "../../../services/CashService";

const CashModal = (props) => {
  const [modalEditLoading, setModalEditLoading] = useState(false);
  const [error, setError] = useState({
    exists: false,
    text: "",
  });

  function handleChange(e) {
    let amount = e.target.value;
    if (!amount || amount === "") amount = 0;
    props.setCash({ _id: props.cash._id, amount });
  }

  // Save
  function saveCash() {
    setModalEditLoading(true);
    const cash = {
      _id: props.cash._id,
      amount: parseFloat(props.cash.amount),
    };
    CashService.updateCash(cash)
      .then((data) => {
        props.loadCash();
        setModalEditLoading(false);
        props.toggleCashEdit();
      })
      .catch((e) => {
        setError({ exists: true, text: e });
      });
  }

  function cancel() {
    props.loadCash();
    props.toggleCashEdit();
  }

  return (
    <>
      <CModal show={props.modalEdit} onClose={props.toggleEdit}>
        <CModalHeader closeButton>Cash Bearbeiten</CModalHeader>
        <CModalBody>
          <center hidden={!modalEditLoading} width="100%">
            <CSpinner style={{ marginLeft: "auto", marginRight: "auto" }} />
          </center>
          <div hidden={modalEditLoading}>
            <CContainer fluid>
              <CRow>
                <CCol sm="12">
                  <CCallout
                    color="danger"
                    className={"bg-white"}
                    visible={error.exists.toString()}
                  >
                    <strong className="h5">{error.text}</strong>
                  </CCallout>
                  <CForm>
                    <CRow>
                      <CCol sm="12">
                        <CFormGroup>
                          <CLabel htmlFor="es-cash">Cash</CLabel>
                          <CInput
                            type="text"
                            id="es-cash"
                            placeholder="Cash"
                            value={props.cash.amount}
                            onChange={handleChange}
                          />
                        </CFormGroup>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCol>
              </CRow>
            </CContainer>
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="primary"
            onClick={saveCash}
            disabled={modalEditLoading}
          >
            Speichern
          </CButton>
          <CButton color="secondary" onClick={cancel}>
            Abbrechen
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default CashModal;
