import {
  CButton,
  CCallout,
  CCol,
  CContainer,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
  CSpinner,
} from "@coreui/react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import DepositsService from "../../../services/DepositsService";

const DeleteModal = (props) => {
  const [modalDeleteLoading, setModalDeleteLoading] = useState(false);

  const [error, setError] = useState({
    exists: false,
    text: "",
  });
  const history = useHistory();

  // Save
  function deleteShare() {
    setModalDeleteLoading(true);

    DepositsService.deleteDeposit(props.depositToDelete._id)
      .then((data) => {
        props.loadDeposits();
        setModalDeleteLoading(false);
        props.resetModalDeleteState();
        props.toggleDelete();
        history.push("/deposits");
      })
      .catch((e) => {
        setError({ exists: true, text: e });
      });
  }

  return (
    <>
      <CModal show={props.modalDelete} onClose={props.toggleDelete}>
        <CModalHeader closeButton>Einzahlung Löschen</CModalHeader>
        <CModalBody>
          <center hidden={!modalDeleteLoading} width="100%">
            <CSpinner style={{ marginLeft: "auto", marginRight: "auto" }} />
          </center>
          <div hidden={modalDeleteLoading}>
            <CContainer fluid>
              <CRow>
                <CCol sm="12">
                  <CCallout
                    color="danger"
                    className={"bg-white"}
                    visible={error.exists.toString()}
                  >
                    <strong className="h5">{error.text}</strong>
                  </CCallout>
                  <CCallout color="danger" className={"bg-white"}>
                    <strong className="h5">
                      Sicher Einzahlung von {props.depositToDelete.member} (
                      {props.depositToDelete.amount}€) vom{" "}
                      {new Date(props.depositToDelete.date).toLocaleDateString(
                        "de-DE"
                      )}{" "}
                      löschen?
                    </strong>
                  </CCallout>
                </CCol>
              </CRow>
            </CContainer>
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="danger"
            onClick={deleteShare}
            disabled={modalDeleteLoading}
          >
            Löschen
          </CButton>
          <CButton color="secondary" onClick={props.toggleDelete}>
            Abbrechen
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default DeleteModal;
