import CIcon from "@coreui/icons-react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
} from "@coreui/react";
import React, { useEffect, useState } from "react";
import CashService from "../../services/CashService";
import DepositsService from "../../services/DepositsService";
import DeleteModal from "../modals/deposits/DeleteModal";
import EditModal from "../modals/deposits/EditModal";
import CashModal from "../modals/cash/CashModal";

import NewModal from "../modals/deposits/NewModal";
import DepositsPerPersonWidget from "./DepositsPerPersonWidget";
import DepositsTable from "./DepositsTable.js";
import CashWidget from "../widgets/CashWidget";

const Deposits = () => {
  // States
  const [deposits, setDeposits] = useState([]);
  const [depositsLoading, setDepositsLoading] = useState(false);
  const [cash, setCash] = useState({ _id: null, amount: 0 });

  const [sumDeposits, setSumDeposits] = useState([]);
  const [sumLoading, setSumLoading] = useState(false);

  const defaultDeposit = {
    _id: null,
    member: "",
    amount: 0,
    date: "",
    remark: "",
    userId: null,
  };
  const [depositToEdit, setDepositToEdit] = useState(defaultDeposit);
  const [depositToDelete, setDepositToDelete] = useState(defaultDeposit);

  const [modalNew, setModalNew] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalCashEdit, setModalCashEdit] = useState(false);

  const [modalDelete, setModalDelete] = useState(false);

  function loadDeposits() {
    setDepositsLoading(true);
    loadSumPerMember();
    DepositsService.getAllDeposits().then((data) => {
      setDeposits(data);
      setDepositsLoading(false);
    });
  }

  function loadSumPerMember() {
    setSumLoading(true);
    DepositsService.getSumPerMember().then((data) => {
      setSumDeposits(data);
      setSumLoading(false);
    });
  }

  function resetModalNewState() {
    setModalNew(defaultDeposit);
  }
  function resetModalEditState() {
    setModalEdit(defaultDeposit);
  }
  function resetModalDeleteState() {
    setModalDelete(defaultDeposit);
  }

  // Modals

  const toggleNew = () => {
    setModalNew(!modalNew);
  };

  const toggleEdit = () => {
    setModalEdit(!modalEdit);
  };

  const toggleCashEdit = () => {
    setModalCashEdit(!modalCashEdit);
  };

  const toggleDelete = () => {
    setModalDelete(!modalDelete);
  };

  function loadCash() {
    CashService.getCash().then((cash) => setCash(cash));
  }

  // Lifecycle
  useEffect(() => {
    loadDeposits();
    loadCash();
  }, []);
  return (
    <>
      <NewModal
        toggleNew={toggleNew}
        loadDeposits={loadDeposits}
        modalNew={modalNew}
        resetModalNewState={resetModalNewState}
        sumDeposits={sumDeposits}
      />
      <EditModal
        toggleEdit={toggleEdit}
        loadDeposits={loadDeposits}
        modalEdit={modalEdit}
        depositToEdit={depositToEdit}
        setDepositToEdit={setDepositToEdit}
        resetModalEditState={resetModalEditState}
      />

      <CashModal
        toggleCashEdit={toggleCashEdit}
        loadCash={loadCash}
        modalEdit={modalCashEdit}
        cash={cash}
        setCash={setCash}
      />

      <DeleteModal
        toggleDelete={toggleDelete}
        loadDeposits={loadDeposits}
        modalDelete={modalDelete}
        depositToDelete={depositToDelete}
        resetModalDeleteState={resetModalDeleteState}
      />

      <DepositsPerPersonWidget sumDeposits={sumDeposits} loading={sumLoading} />

      <CRow>
        <CCol>
          <CCard>
            <CCardHeader>
              <span className="h4">
                Einzahlungen verwalten (akutell{" "}
                {parseFloat(cash.amount).toFixed(2)}€ Cash)
              </span>
              <div className="float-right btn-pill ml-2">
                <CButton
                  color="success"
                  size="sm"
                  className="float-right btn-pill ml-2"
                  onClick={toggleCashEdit}
                  title="Cash bearbeiten"
                >
                  <CIcon name="cilCash" />
                </CButton>
                <CButton
                  color="dark"
                  size="sm"
                  className="float-right btn-pill ml-2"
                  onClick={toggleNew}
                  title="Neue Einzahlung"
                >
                  <CIcon name="cil-plus" />
                </CButton>
              </div>
            </CCardHeader>
            <CCardBody>
              <DepositsTable
                deposits={deposits}
                loading={depositsLoading}
                setDepositToEdit={setDepositToEdit}
                toggleEdit={toggleEdit}
                depositToDelete={depositToDelete}
                setDepositToDelete={setDepositToDelete}
                toggleDelete={toggleDelete}
              />
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </>
  );
};

export default Deposits;
