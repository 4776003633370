"use strict";

export default class HttpService {
  constructor() {}

  static apiURL() {
    return "http://localhost:3000";
  }

  static get(url, onSuccess, onError) {
    let token = window.localStorage["jwtToken"];
    let header = new Headers();
    if (token) {
      header.append("Authorization", `JWT ${token}`);
    }

    fetch(url, {
      method: "GET",
      headers: header,
    })
      .then((resp) => {
        if (this.checkIfUnauthorized(resp)) {
          window.location = "#/login";
        } else {
          return resp.json();
        }
      })
      .then((resp) => {
        if (resp.error) {
          onError(resp.error);
        } else {
          if (resp.hasOwnProperty("token")) {
            window.localStorage["jwtToken"] = resp.token;
          }
          onSuccess(resp);
        }
      })
      .catch((e) => {
        onError(e.message);
      });
  }

  static put(url, data, onSuccess, onError) {
    let token = window.localStorage["jwtToken"];
    let header = new Headers();
    if (token) {
      header.append("Authorization", `JWT ${token}`);
    }
    header.append("Content-Type", "application/json");

    fetch(url, {
      method: "PUT",
      headers: header,
      body: JSON.stringify(data),
    })
      .then((resp) => {
        if (this.checkIfUnauthorized(resp)) {
          window.location = "#/login";
          return;
        } else {
          return resp.json();
        }
      })
      .then((resp) => {
        if (resp.error) {
          onError(resp.error);
        } else {
          if (resp.hasOwnProperty("token")) {
            window.localStorage["jwtToken"] = resp.token;
          }
          onSuccess(resp);
        }
      })
      .catch((e) => {
        onError(e.message);
      });
  }

  static post(url, data, onSuccess, onError) {
    let token = window.localStorage["jwtToken"];
    let header = new Headers();
    if (token) {
      header.append("Authorization", `JWT ${token}`);
    }
    header.append("Content-Type", "application/json");

    fetch(url, {
      method: "POST",
      headers: header,
      body: JSON.stringify(data),
    })
      .then((resp) => {
        if (this.checkIfUnauthorized(resp)) {
          window.location = "#/login";
          return resp.json();
        } else {
          return resp.json();
        }
      })
      .then((resp) => {
        if (resp && resp.error) {
          onError(resp.error);
        } else {
          if (resp.hasOwnProperty("token")) {
            window.localStorage["jwtToken"] = resp.token;
          }
          onSuccess(resp);
        }
      })
      .catch((e) => {
        onError(e.message);
      });
  }

  // Used only for special form-data requirement of image upload
  static postImage(url, data, onSuccess, onError) {
    let token = window.localStorage["jwtToken"];
    let header = new Headers();
    if (token) {
      header.append("Authorization", `JWT ${token}`);
    }
    fetch(url, {
      method: "POST",
      headers: header,
      body: data,
    })
      .then((resp) => {
        if (this.checkIfUnauthorized(resp)) {
          window.location = "#/login";
          return;
        } else {
          return resp.json();
        }
      })
      .then((resp) => {
        if (resp.error) {
          onError(resp.error);
        } else {
          if (resp.hasOwnProperty("token")) {
            window.localStorage["jwtToken"] = resp.token;
          }
          onSuccess(resp);
        }
      })
      .catch((e) => {
        onError(e.message);
      });
  }

  static remove(url, onSuccess, onError) {
    let token = window.localStorage["jwtToken"];
    let header = new Headers();
    if (token) {
      header.append("Authorization", `JWT ${token}`);
    }

    fetch(url, {
      method: "DELETE",
      headers: header,
    })
      .then((resp) => {
        if (this.checkIfUnauthorized(resp)) {
          window.location = "#/login";
          return;
        } else {
          return resp.json();
        }
      })
      .then((resp) => {
        if (resp.error) {
          onError(resp.error);
        } else {
          onSuccess(resp);
        }
      })
      .catch((e) => {
        onError(e.message);
      });
  }

  static checkIfUnauthorized(res) {
    if (res.status === 401) {
      window.localStorage.removeItem("jwtToken");
      return true;
    }
    return false;
  }

  static yahooRequest(
    url,
    // method,
    hostname,
    apiKey,
    useQueryString,
    onSuccess,
    onError
  ) {
    let headers = new Headers();
    headers.append("x-rapidapi-key", apiKey);
    headers.append("x-rapidapi-host", hostname);
    headers.append("useQueryString", useQueryString);

    fetch(url, {
      method: "GET",
      headers: headers,
    })
      .then((resp) => {
        return resp.json();
      })
      .then((resp) => {
        if (resp.error) {
          onError(resp.error);
        } else {
          onSuccess(resp);
        }
      })
      .catch((e) => {
        onError(e.message);
      });
  }
}
