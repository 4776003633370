import {
  CButton,
  CCallout,
  CCol,
  CContainer,
  CForm,
  CFormGroup,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
  CSpinner,
} from "@coreui/react";
import React, { useState } from "react";
import DepositsService from "../../../services/DepositsService";

const EditModal = (props) => {
  const [modalEditLoading, setModalEditLoading] = useState(false);

  const [error, setError] = useState({
    exists: false,
    text: "",
  });

  function handleChange(e) {
    // e.preventDefault();
    var value = e.target.value;
    var name = e.target.name;
    let share = { ...props.depositToEdit };
    share[name] = value;
    props.setDepositToEdit(share);
  }

  // Save
  function saveDeposit() {
    setModalEditLoading(true);
    const deposit = {
      _id: props.depositToEdit._id,
      member: props.depositToEdit.member,
      amount: parseFloat(props.depositToEdit.amount),
      date: props.depositToEdit.date,
      remark: props.depositToEdit.remark,
      userId: props.depositToEdit.userId,
    };
    DepositsService.updateDeposit(deposit)
      .then((data) => {
        props.loadDeposits();
        setModalEditLoading(false);
        props.resetModalEditState();
        props.toggleEdit();
      })
      .catch((e) => {
        setError({ exists: true, text: e });
      });
  }
  const dateOptions = { year: "numeric", month: "2-digit", day: "2-digit" };

  return (
    <>
      <CModal show={props.modalEdit} onClose={props.toggleEdit}>
        <CModalHeader closeButton>Einzahlung Bearbeiten</CModalHeader>
        <CModalBody>
          <center hidden={!modalEditLoading} width="100%">
            <CSpinner style={{ marginLeft: "auto", marginRight: "auto" }} />
          </center>
          <div hidden={modalEditLoading}>
            <CContainer fluid>
              <CRow>
                <CCol sm="12">
                  <CCallout
                    color="danger"
                    className={"bg-white"}
                    visible={error.exists.toString()}
                  >
                    {/* <small className="text-muted">Recurring Clients</small> */}
                    {/* <br /> */}
                    <strong className="h5">{error.text}</strong>
                  </CCallout>
                  <CForm>
                    <CRow>
                      <CCol sm="6">
                        <CFormGroup>
                          <CLabel htmlFor="es-member">Einzahlung von</CLabel>
                          <CInput
                            disabled={true}
                            type="text"
                            id="es-member"
                            name="member"
                            placeholder="Person"
                            value={props.depositToEdit.member}
                            onChange={handleChange}
                            //   autoComplete="email"
                          />
                        </CFormGroup>
                      </CCol>
                      <CCol sm="6">
                        <CFormGroup width="50%">
                          <CLabel htmlFor="es-date">Einzahlungsdatum</CLabel>
                          <CInput
                            format="yyyy-MM-dd"
                            type="date"
                            id="es-date"
                            name="date"
                            placeholder="Einzahlungsdatum"
                            value={new Date(
                              props.depositToEdit.date
                            ).toLocaleDateString("fr-CA")}
                            onChange={handleChange}
                          />
                        </CFormGroup>
                      </CCol>
                    </CRow>
                    <CRow>
                      <CCol sm="4">
                        <CFormGroup width="50%">
                          <CLabel htmlFor="es-amount">Höhe</CLabel>
                          <CInput
                            type="number"
                            id="es-amount"
                            name="amount"
                            placeholder="Höhe"
                            value={props.depositToEdit.amount}
                            onChange={handleChange}
                          />
                        </CFormGroup>
                      </CCol>
                      <CCol sm="8">
                        <CFormGroup>
                          <CLabel htmlFor="es-remark">Bemerkung</CLabel>
                          <CInput
                            type="text"
                            id="es-remark"
                            name="remark"
                            placeholder="Bemerkung"
                            value={props.depositToEdit.remark}
                            onChange={handleChange}
                          />
                        </CFormGroup>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCol>
              </CRow>
            </CContainer>
          </div>
        </CModalBody>
        <CModalFooter>
          <CButton
            color="primary"
            onClick={saveDeposit}
            disabled={modalEditLoading}
          >
            Speichern
          </CButton>
          <CButton color="secondary" onClick={props.toggleEdit}>
            Abbrechen
          </CButton>
        </CModalFooter>
      </CModal>
    </>
  );
};

export default EditModal;
