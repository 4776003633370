import CIcon from "@coreui/icons-react";
import { CButton, CDataTable } from "@coreui/react";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

const DepositsTable = (props) => {
  const [details, setDetails] = useState([]);
  const history = useHistory();
  const fields = [
    { key: "member", label: "Name" },
    { key: "date", label: "Einzahlungsdatum" },
    { key: "amount", label: "Einzahlung" },
    { key: "remark", label: "Bemerkung" },
    {
      key: "edit",
      label: "",
      _style: { width: "1%" },
      sorter: false,
      filter: false,
    },
    {
      key: "delete",
      label: "",
      _style: { width: "1%" },
      sorter: false,
      filter: false,
    },
  ];

  const getBadgeColor = (value) => {
    if (value > 0) return "success";
    if (value < 0) return "danger";
    return "secondary";
  };
  const getTextColor = (value) => {
    if (value > 0) return "#2eb85c";
    if (value < 0) return "#e55353";
  };
  const dateOptions = { year: "numeric", month: "2-digit", day: "2-digit" };

  return (
    <>
      <CDataTable
        loading={props.loading}
        items={props.deposits}
        fields={fields}
        columnFilter
        tableFilter
        // footer
        itemsPerPageSelect
        itemsPerPage={5}
        hover
        sorter
        pagination
        scopedSlots={{
          member: (item) => {
            return (
              <td>
                <div>
                  <strong>{item.member}</strong>
                </div>
              </td>
            );
          },
          date: (item) => {
            return (
              <td>
                <div>
                  {new Date(item.date).toLocaleDateString("de-DE", dateOptions)}
                </div>
              </td>
            );
          },
          amount: (item) => {
            return (
              <td>
                <div>
                  {/* textColor={getBadgeColor(item.profitLossPercent)}> */}
                  {parseFloat(item.amount).toFixed(2)}
                </div>
              </td>
            );
          },
          remark: (item) => {
            return (
              <td>
                <div>
                  <span>{item.remark}</span>
                </div>
              </td>
            );
          },

          edit: (item, index) => {
            return (
              <td className="py-2">
                <CButton
                  color="dark"
                  variant="outline"
                  shape="pill"
                  size="sm"
                  onClick={() => {
                    props.setDepositToEdit(item);
                    props.toggleEdit();
                  }}
                >
                  <CIcon name="cilPencil"></CIcon>
                </CButton>
              </td>
            );
          },
          delete: (item, index) => {
            return (
              <td className="py-2">
                <CButton
                  color="danger"
                  variant="outline"
                  shape="pill"
                  size="sm"
                  onClick={() => {
                    props.setDepositToDelete(item);
                    props.toggleDelete();
                  }}
                >
                  <CIcon name="cilTrash"></CIcon>
                </CButton>
              </td>
            );
          },
        }}
      />
    </>
  );
};

export default DepositsTable;
