import React, { Component } from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import "./scss/style.scss";
import UserService from "./services/UserService";
import Deposits from "./views/deposits/Deposits";
// import Expenses from "./views/expenses/Expenses";
// import Portfolio from "./views/portfolio/Portfolio";
import { Sugar } from "react-preloaders";
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Register = React.lazy(() => import("./views/pages/register/Register"));
const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));
const Landing = React.lazy(() => import("./views/pages/landing/Landing"));
const Portfolio = React.lazy(() => import("./views/portfolio/Portfolio"));
const Settings = React.lazy(() => import("./views/settings/Settings"));
const MeetingNotes = React.lazy(() => import("./views/meetingNotes/Notes"));
const NotesEdit = React.lazy(() => import("./views/meetingNotes/NotesEdit"));
const Expenses = React.lazy(() => import("./views/expenses/Expenses"));

const PortfolioDetails = React.lazy(() =>
  import("./views/portfolio/details/PortfolioDetails")
);

function isAuthenticated() {
  return UserService.isAuthenticated();
}

class App extends Component {
  render() {
    return (
      <>
        <Sugar></Sugar>
        <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route
                exact
                path="/login"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/register"
                name="Register Page"
                render={(props) => <Register {...props} />}
              />
              <Route
                exact
                path="/404"
                name="Page 404"
                render={(props) => {
                  if (isAuthenticated()) {
                    return <Page404 {...props} />;
                  } else {
                    return <Redirect to={"/login"} />;
                  }
                }}
              />
              <Route
                exact
                path="/500"
                name="Page 500"
                render={(props) => {
                  if (isAuthenticated()) {
                    return <Page500 {...props} />;
                  } else {
                    return <Login {...props} />;
                  }
                }}
              />
              <Route
                path="/landing"
                name="Landing"
                render={(props) => {
                  if (isAuthenticated()) {
                    return <Landing {...props} />;
                  } else {
                    return <Redirect to={"/login"} />;
                  }
                }}
              />
              <Route
                path="/"
                name="Home"
                render={(props) => {
                  if (isAuthenticated()) {
                    return <TheLayout {...props} />;
                  } else {
                    return <Redirect to={"/login"} />;
                  }
                }}
              />
              <Route
                exact
                path="/portfolio/:id"
                name="Details"
                render={(props) => {
                  if (isAuthenticated()) {
                    return <PortfolioDetails {...props} />;
                  } else {
                    return <Redirect to={"/login"} />;
                  }
                }}
              />
              <Route
                exact
                path="/portfolio"
                name="Portfolio"
                render={(props) => {
                  if (isAuthenticated()) {
                    return <Portfolio {...props} />;
                  } else {
                    return <Redirect to={"/login"} />;
                  }
                }}
              />
              <Route
                exact
                path="/deposits"
                name="Einzahlungen"
                render={(props) => {
                  if (isAuthenticated()) {
                    return <Deposits {...props} />;
                  } else {
                    return <Redirect to={"/login"} />;
                  }
                }}
              />
              <Route
                exact
                path="/expenses"
                name="Ausgaben"
                render={(props) => {
                  if (isAuthenticated()) {
                    return <Expenses {...props} />;
                  } else {
                    return <Redirect to={"/login"} />;
                  }
                }}
              />
              <Route
                exact
                path="/settings"
                name="Einstellungen"
                render={(props) => {
                  if (isAuthenticated()) {
                    return <Settings {...props} />;
                  } else {
                    return <Redirect to={"/login"} />;
                  }
                }}
              />
              <Route
                exact
                path="/notes/:id/edit"
                name="Bearbeiten"
                render={(props) => {
                  if (isAuthenticated()) {
                    return <NotesEdit {...props} />;
                  } else {
                    return <Redirect to={"/login"} />;
                  }
                }}
              />
              <Route
                exact
                path="/notes"
                name="Meeting Notizen"
                render={(props) => {
                  if (isAuthenticated()) {
                    return <MeetingNotes {...props} />;
                  } else {
                    return <Redirect to={"/login"} />;
                  }
                }}
              />
            </Switch>
          </React.Suspense>
        </HashRouter>
      </>
    );
  }
}

export default App;
