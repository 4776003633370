import React from "react";
import CreatableSelect from "react-select/creatable";

// import { colourOptions } from "../data";
const MemberSelect = (props) => {
  const handleChange = (newValue, actionMeta) => {
    console.log(newValue);
    let deposit = { ...props.newDeposit };
    if (newValue) {
      deposit["member"] = newValue.value;
    } else {
      deposit["member"] = null;
    }
    props.setNewDeposit(deposit);
  };

  return (
    <CreatableSelect
      isClearable
      name="member"
      placeholder="Einzahlung von"
      onChange={handleChange}
      options={props.options}
    />
  );
};
export default MemberSelect;
