"use strict";

import HttpService from "./HttpService";

export default class UserService {
  constructor() {}

  static baseURL() {
    return process.env.REACT_APP_BACKEND_URL + "/auth";
  }

  static register(username, password) {
    return new Promise((resolve, reject) => {
      HttpService.post(
        `${UserService.baseURL()}/register`,
        {
          username,
          password,
        },
        function (data) {
          resolve(data);
        },
        function (textStatus) {
          reject(textStatus);
        }
      );
    });
  }

  static login(username, password) {
    return new Promise((resolve, reject) => {
      HttpService.post(
        `${UserService.baseURL()}/login`,
        {
          username,
          password,
        },
        function (data) {
          resolve(data);
        },
        function (textStatus) {
          console.log(textStatus);
          reject(textStatus);
        }
      );
    });
  }

  // delete jwt token and request to backend to make it invalid
  static logout() {
    return new Promise((resolve, reject) => {
      window.localStorage.removeItem("jwtToken");
      window.location.reload();
      resolve();
    });
    // const token = window.localStorage["jwtToken"];
    // return new Promise((resolve, reject) => {
    //   HttpService.post(
    //     `${UserService.baseURL()}/logout`,
    //     { token },
    //     function (data) {
    //       window.localStorage.removeItem("jwtToken");
    //       window.location.reload();
    //       resolve(data);
    //     },
    //     function (textStatus) {
    //       reject(textStatus);
    //     }
    //   );
    // });
  }

  // Get user from jwt token
  static getCurrentUser() {
    let token = window.localStorage["jwtToken"];
    if (!token) return {};

    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace("-", "+").replace("_", "/");

    return {
      id: JSON.parse(window.atob(base64)).id,
      username: JSON.parse(window.atob(base64)).username,
    };
  }

  // check if token is valid
  static isAuthenticated() {
    const token = window.localStorage["jwtToken"];
    if (token) {
      let base64Url = token.split(".")[1];
      let base64 = base64Url.replace("-", "+").replace("_", "/");
      const exp = JSON.parse(window.atob(base64)).exp;
      if (Date.now() >= exp * 1000) {
        return false;
      }

      return true;
    } else {
      return false;
    }
  }

  static updateUser(user) {
    return new Promise((resolve, reject) => {
      HttpService.put(
        `${UserService.baseURL()}/${user.id}`,
        user,
        function (data) {
          resolve(UserService.getCurrentUser());
        },
        function (textStatus) {
          reject(textStatus);
        }
      );
    });
  }

  static changePassword(user) {
    return new Promise((resolve, reject) => {
      HttpService.put(
        `${UserService.baseURL()}/${
          UserService.getCurrentUser().id
        }/changePassword`,
        user,
        function (data) {
          resolve(UserService.getCurrentUser());
        },
        function (textStatus) {
          reject(textStatus);
        }
      );
    });
  }
}
