import {
  CCallout,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
} from "@coreui/react";
import React from "react";
import { PropagateLoader } from "react-spinners";
const DepositsPerPersonWidget = (props) => {
  return (
    <>
      <CCard>
        <CCardHeader>
          <span className="h4">Einzahlungen pro Person</span>
        </CCardHeader>
        <CCardBody>
          <CRow>
            <CCol xs="12">
              <CRow>
                {props.loading ? (
                  <CCol xs="12">
                    <div className="d-flex justify-content-center">
                      <PropagateLoader />
                    </div>
                  </CCol>
                ) : (
                  <CCol xs="6" md="3" xl="2">
                    <CCallout color="primary">
                      <small className="text-muted">
                        <b>Gesamt</b>
                      </small>
                      <br />
                      <strong className="h4">
                        {props.sumDeposits
                          .reduce((acc, curr) => acc + curr.total, 0)
                          .toFixed(2)}
                        €
                      </strong>
                    </CCallout>
                  </CCol>
                )}
                {props.sumDeposits.map((item, i) => {
                  return (
                    <CCol xs="6" md="3" xl="2">
                      <CCallout color="info">
                        <small className="text-muted">
                          <b>{item._id}</b>
                        </small>
                        <br />
                        <strong className="h4">
                          {parseFloat(item.total).toFixed(2)}€
                        </strong>
                      </CCallout>
                    </CCol>
                  );
                })}
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </>
  );
};

export default DepositsPerPersonWidget;
