import CIcon from "@coreui/icons-react";
import { CCol, CWidgetProgressIcon } from "@coreui/react";
import React, { useState } from "react";
import PuffLoader from "react-spinners/PuffLoader";

const CashWidget = (props) => {
  const getColor = (value) => {
    if (value > 0) return "success";
    if (value < 0) return "danger";
  };

  const getValueOrSpinner = (value) => {
    if (props.loading)
      return (
        <center style={{ marginLeft: "auto", marginRight: "auto" }}>
          <PuffLoader
            // css={override}
            size={30}
            // color={"#123abc"}
            // loading={this.state.loading}
          />
        </center>
      );
    // <CSpinner style={{ marginLeft: "auto", marginRight: "auto" }} />;
    else return value;
  };

  return (
    <>
      <CCol sm="6" md="3">
        <CWidgetProgressIcon
          header={getValueOrSpinner(props.cash + "€")}
          text={
            "Cash | optimal (5%): " +
            (props.totalInvestment * 0.05).toFixed(0) +
            "€"
          }
          color="gradient-success"
          value={(
            (props.cash /
              (parseFloat(
                parseFloat(props.totalInvestment) + parseFloat(props.cash)
              ) *
                0.05)) *
            100
          ).toFixed(0)}
        >
          <CIcon name="cil-dollar" height={36} />
        </CWidgetProgressIcon>
      </CCol>
    </>
  );
};

export default CashWidget;
