"use strict";

import HttpService from "./HttpService";

export default class CashService {
  static baseURL() {
    return process.env.REACT_APP_BACKEND_URL + "/cash";
  }

  // Get cash
  static getCash() {
    return new Promise((resolve, reject) => {
      HttpService.get(
        `${CashService.baseURL()}/`,
        function (data) {
          if (data != undefined || Object.keys(data).length !== 0) {
            resolve(data);
          } else {
            reject("Error while retrieving cash");
          }
        },
        function (textStatus) {
          reject(textStatus);
        }
      );
    });
  }

  // update cash
  static updateCash(cash) {
    return new Promise((resolve, reject) => {
      HttpService.put(
        `${CashService.baseURL()}/${cash._id}`,
        cash,
        function (data) {
          resolve(data);
        },
        function (textStatus) {
          reject(textStatus);
        }
      );
    });
  }

  // create a new cash
  // static createCash(cash) {
  //   return new Promise((resolve, reject) => {
  //     HttpService.post(
  //       CashService.baseURL(),
  //       cash,
  //       function (data) {
  //         resolve(data);
  //       },
  //       function (textStatus) {
  //         reject(textStatus);
  //       }
  //     );
  //   });
  // }
}
